(function($) {
  $.extend($.summernote.lang, {
    'id-ID': {
      font: {
        bold: 'Tebal',
        italic: 'Miring',
        underline: 'Garis bawah',
        clear: 'Bersihkan gaya',
        height: 'Jarak baris',
        name: 'Jenis Tulisan',
        strikethrough: 'Coret',
        subscript: 'Subscript',
        superscript: 'Superscript',
        size: 'Ukuran font',
      },
      image: {
        image: 'Gambar',
        insert: 'Sisipkan gambar',
        resizeFull: 'Ukuran penuh',
        resizeHalf: 'Ukuran 50%',
        resizeQuarter: 'Ukuran 25%',
        floatLeft: 'Rata kiri',
        floatRight: 'Rata kanan',
        floatNone: 'Tanpa perataan',
        shapeRounded: 'Bentuk: Membundar',
        shapeCircle: 'Bentuk: Bundar',
        shapeThumbnail: 'Bentuk: Thumbnail',
        shapeNone: 'Bentuk: Tidak ada',
        dragImageHere: 'Tarik gambar ke area ini',
        dropImage: 'Letakkan gambar atau teks',
        selectFromFiles: 'Pilih gambar dari berkas',
        maximumFileSize: 'Ukuran maksimal berkas',
        maximumFileSizeError: 'Ukuran maksimal berkas terlampaui.',
        url: 'URL gambar',
        remove: 'Hapus Gambar',
        original: 'Original',
      },
      video: {
        video: 'Video',
        videoLink: 'Link video',
        insert: 'Sisipkan video',
        url: 'Tautan video',
        providers: '(YouTube, Vimeo, Vine, Instagram, DailyMotion atau Youku)',
      },
      link: {
        link: 'Tautan',
        insert: 'Tambah tautan',
        unlink: 'Hapus tautan',
        edit: 'Edit',
        textToDisplay: 'Tampilan teks',
        url: 'Tautan tujuan',
        openInNewWindow: 'Buka di jendela baru',
      },
      table: {
        table: 'Tabel',
        addRowAbove: 'Tambahkan baris ke atas',
        addRowBelow: 'Tambahkan baris ke bawah',
        addColLeft: 'Tambahkan kolom ke kiri',
        addColRight: 'Tambahkan kolom ke kanan',
        delRow: 'Hapus baris',
        delCol: 'Hapus kolom',
        delTable: 'Hapus tabel',
      },
      hr: {
        insert: 'Masukkan garis horizontal',
      },
      style: {
        style: 'Gaya',
        p: 'p',
        blockquote: 'Kutipan',
        pre: 'Kode',
        h1: 'Heading 1',
        h2: 'Heading 2',
        h3: 'Heading 3',
        h4: 'Heading 4',
        h5: 'Heading 5',
        h6: 'Heading 6',
      },
      lists: {
        unordered: 'Pencacahan',
        ordered: 'Penomoran',
      },
      options: {
        help: 'Bantuan',
        fullscreen: 'Layar penuh',
        codeview: 'Kode HTML',
      },
      paragraph: {
        paragraph: 'Paragraf',
        outdent: 'Outdent',
        indent: 'Indent',
        left: 'Rata kiri',
        center: 'Rata tengah',
        right: 'Rata kanan',
        justify: 'Rata kanan kiri',
      },
      color: {
        recent: 'Warna sekarang',
        more: 'Selengkapnya',
        background: 'Warna latar',
        foreground: 'Warna font',
        transparent: 'Transparan',
        setTransparent: 'Atur transparansi',
        reset: 'Atur ulang',
        resetToDefault: 'Kembalikan kesemula',
      },
      shortcut: {
        shortcuts: 'Jalan pintas',
        close: 'Tutup',
        textFormatting: 'Format teks',
        action: 'Aksi',
        paragraphFormatting: 'Format paragraf',
        documentStyle: 'Gaya dokumen',
        extraKeys: 'Shortcut tambahan',
      },
      help: {
        'insertParagraph': 'Tambahkan paragraf',
        'undo': 'Urungkan perintah terakhir',
        'redo': 'Kembalikan perintah terakhir',
        'tab': 'Tab',
        'untab': 'Untab',
        'bold': 'Mengaktifkan gaya tebal',
        'italic': 'Mengaktifkan gaya italic',
        'underline': 'Mengaktifkan gaya underline',
        'strikethrough': 'Mengaktifkan gaya strikethrough',
        'removeFormat': 'Hapus semua gaya',
        'justifyLeft': 'Atur rata kiri',
        'justifyCenter': 'Atur rata tengah',
        'justifyRight': 'Atur rata kanan',
        'justifyFull': 'Atur rata kiri-kanan',
        'insertUnorderedList': 'Nyalakan urutan tanpa nomor',
        'insertOrderedList': 'Nyalakan urutan bernomor',
        'outdent': 'Outdent di paragraf terpilih',
        'indent': 'Indent di paragraf terpilih',
        'formatPara': 'Ubah format gaya tulisan terpilih menjadi paragraf',
        'formatH1': 'Ubah format gaya tulisan terpilih menjadi Heading 1',
        'formatH2': 'Ubah format gaya tulisan terpilih menjadi Heading 2',
        'formatH3': 'Ubah format gaya tulisan terpilih menjadi Heading 3',
        'formatH4': 'Ubah format gaya tulisan terpilih menjadi Heading 4',
        'formatH5': 'Ubah format gaya tulisan terpilih menjadi Heading 5',
        'formatH6': 'Ubah format gaya tulisan terpilih menjadi Heading 6',
        'insertHorizontalRule': 'Masukkan garis horizontal',
        'linkDialog.show': 'Tampilkan Link Dialog',
      },
      history: {
        undo: 'Kembali',
        redo: 'Ulang',
      },
      specialChar: {
        specialChar: 'KARAKTER KHUSUS',
        select: 'Pilih karakter khusus',
      },
    },
  });
})(jQuery);
